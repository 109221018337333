import { Component, OnInit } from '@angular/core';
import {FinancialinstrumentsService} from "../shared/services/financialinstruments.service";
import {Instrument} from "../shared/interfaces/instrument";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-instrument-page',
  templateUrl: './instrument-page.component.html',
  styleUrls: ['./instrument-page.component.css']
})
export class InstrumentPageComponent implements OnInit {
  panelOpenState = false;
  instruments: Instrument[];
  settings = {
    noDataMessage: 'Список пуст',
    editDataMessage: 'Редактировать',
    actions: { columnTitle: 'Действия'},
    add: {
      addButtonContent: 'Добавить',
      createButtonContent: 'Сохранить',
      cancelButtonContent: 'Отмена',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: 'Редактировать',
      saveButtonContent: 'Сохранить',
      cancelButtonContent: 'Отменить',
      confirmSave: true
    },
    delete: {
      deleteButtonContent: 'Удалить',
      saveButtonContent: 'Подтвердить',
      cancelButtonContent: 'Отменить',
      confirmDelete: true
    },
    columns: {
      code: {
        title: 'Код',
      },
      clear_ode: {
        title: 'Сокращёный ключ'
      },
      key: {
        title: 'Ключ'
      },
      //isDefault: {
      //  title: 'Стандртный',
      //  valuePrepareFunction: (col, row) => {
      //    return `${row.isDefault ? 'Да' : 'Нет'}`;
      //  },
      //  filter: {
      //    type: 'list',
      //    config: {
      //      selectText: 'Без фильтра',
      //      list: [
      //        {value: true, title: 'Да'},
      //        {value: false, title: 'Нет'},
      //      ],
      //    },
      //  },
      //},
      // userId: {
      //   title: 'Id пользователя'
      // },
      isDelete: {
        title: 'Удалён',
        editable: false,
        addable: false,
        valuePrepareFunction: (col, row) => {
          return `${row.isDelete ? 'Удален' : 'Не удален'}`;
        },
        filter: {
          type: 'list',
          config: {
            selectText: 'Без фильтра',
            list: [
              {value: true, title: 'Удален'},
              {value: false, title: 'Не удален'},
            ],
          },
        }
      },
      description: {
        title: 'Описание'
      },
      icon: {
        title: 'Иконка',
        type: 'html'
      }
    }
  };
  openRemove = false;
  removeInst: Instrument;
  constructor(private instrumentServices: FinancialinstrumentsService,
              private notifier: NotifierService) { }

  ngOnInit(): void {
      this.getInstruments();
  }

  getInstruments() {
    this.instrumentServices.getFinancialinstruments().subscribe(instruments => {
      var data = instruments as Instrument[];
      data.forEach(function (value) {
        value.icon = "<img class=\"small_icon\" src='https://api.nfksber.ru/images/" + value.code + ".png' alt='none' /> <br /> <a href=\"/upload-img\" >Загрузить новое изображение</a>";
      });  
      this.instruments = data;
    });
  }

  deleteIstrument(instrument: Instrument) {
    this.instrumentServices.deleteFinancialinstruments(instrument.id).subscribe( data => {
      this.removeInst = undefined;
      this.notifier.notify('success', 'Удалено!');
      this.instruments = undefined;
      this.getInstruments();
    }, error => {
      this.notifier.notify('error', 'Ошибка(Что-то пошло не так)!');
      console.log('error', error);
    });
  }

  confirmEdit(event: any, editConfirm: string) {
    console.log(event.confirm);
    this.instrumentServices.putFinancialinstruments(event.newData).subscribe( data => {
      this.notifier.notify('success', 'Сохранено!');
        event.confirm.resolve();
      },
      error => {
        this.notifier.notify('error', 'Ошибка!');
        event.confirm.reject();
      });
  }

  createInstrument(event: any) {
    this.instrumentServices.postFinancialinstruments(event.newData).subscribe( data => {
      this.notifier.notify('success', 'Добавлен!');
      this.instruments = undefined;
      this.getInstruments();
    }, error => {
      console.log('error', error);
      this.notifier.notify('error', 'Ошибка!(Несохранено!)');
    });
  }

  deleteConfirm(event: any) {
    this.removeInst = event.data;
  }
}
