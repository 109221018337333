<div class="page">
  <h3>Логи</h3>

  <table *ngIf="logs && logs.length > 0">
    <tr *ngFor="let log of logs">
      <td>
        {{log.level}}
      </td>
      <td>
        {{log.methodName}}
      </td>
      <td>
        {{log.info}}
      </td>
    </tr>
  </table>

  <p *ngIf="logs && logs.length === 0">
    Логи отсутствуют
  </p>
</div>
