import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Conf} from "../conf";

@Injectable({
  providedIn: 'root'
})
export class UsersServices {

  constructor(private http: HttpClient) { }

  getAllUsers(){
    return this.http.get(`${Conf.APIBASEURL}api/users/all`);
  }

  getUser(id) {
    return this.http.get(`${Conf.APIBASEURL}api/users/${id}`);
  }

  senUser(user) {
    return this.http.put(`${Conf.APIBASEURL}api/users`, user);
  }
}
