<div class="page">
  <h3>Панель управления</h3>

  <div class="info-wrap" *ngIf="information">
    <div class="card card-stats">
      <div class="card-header card-header-warning card-header-icon">
        <div class="card-icon">
          <i class="material-icons">content_copy</i>
        </div>
        <p class="card-category">Колличество финансовых инструментов</p>
        <h3 class="card-title">
          {{information.countFinInst}}
        </h3>
      </div>
      <div class="card-footer">
        <div class="stats">
          <i class="material-icons text-danger">warning</i>
          <a routerLink="/instruments-page">Финансовые инструменты</a>
        </div>
      </div>
    </div>

    <div class="card card-stats">
      <div class="card-header card-header-danger card-header-icon">
        <div class="card-icon">
          <i class="material-icons">content_copy</i>
        </div>
        <p class="card-category">Кол-во логов</p>
        <h3 class="card-title">
          {{information['countLogs']}}
        </h3>
      </div>
      <div class="card-footer">
        <div class="stats">
          <i class="material-icons text-danger">date_range</i>
          <a routerLink="/log-page">Логи</a>
        </div>
      </div>
    </div>

    <div class="card card-stats">
      <div class="card-header card-header-success card-header-icon">
        <div class="card-icon">
          <i class="material-icons">content_copy</i>
        </div>
        <p class="card-category">Запросов обратной связи</p>
        <h3 class="card-title">
          {{information['countRequestFeedback']}}
        </h3>
      </div>
      <div class="card-footer">
        <div class="stats">
          <i class="material-icons text-danger">local_offer</i>
          <a routerLink="/feedback">Список запросов на обратную связь</a>
        </div>
      </div>
    </div>

    <div class="card card-stats">
      <div class="card-header card-header-warning card-header-icon">
        <div class="card-icon">
          <i class="material-icons">content_copy</i>
        </div>
        <p class="card-category">Не прочитаных запросов обратной связи</p>
        <h3 class="card-title">
          {{information['countNewRequestFeedback']}}
        </h3>
      </div>
      <div class="card-footer">
        <div class="stats">
          <i class="material-icons text-danger">warning</i>
          <a routerLink="/feedback">Список запросов на обратную связь</a>
        </div>
      </div>
    </div>

    <div class="card card-stats">
      <div class="card-header card-header-danger card-header-icon">
        <div class="card-icon">
          <i class="material-icons">store</i>
        </div>
        <p class="card-category">Кол-во пользователей</p>
        <h3 class="card-title">
          {{information['countUsers']}}
        </h3>
      </div>
      <div class="card-footer">
        <div class="stats">
          <i class="material-icons text-danger">date_range</i>
          <a routerLink="/users"> Пользователи</a>
        </div>
      </div>
    </div>

    <div class="card card-stats">
      <div class="card-header card-header-success card-header-icon">
        <div class="card-icon">
          <i class="material-icons">content_copy</i>
        </div>
        <p class="card-category">Кол-во запросов востановления пароля</p>
        <h3 class="card-title">
          {{information['countRequestRecoverPassowrds']}}
        </h3>
      </div>
      <div class="card-footer">
        <div class="stats">
          <i class="material-icons text-danger">local_offer</i>
          <a routerLink="/requests-recover-password">Список запросов востановления пароля</a>
        </div>
      </div>
    </div>

    <div class="card card-stats">
      <div class="card-header card-header-warning card-header-icon">
        <div class="card-icon">
          <i class="material-icons">upload</i>
        </div>
        <p class="card-category">Загрузка иконок для инструментов</p>
        <h3 class="card-title">
          {{information.countFinInst}}
        </h3>
      </div>
      <div class="card-footer">
        <div class="stats">
          <i class="material-icons text-danger">warning</i>
          <a routerLink="/upload-img">Загрузка иконок для инструментов</a>
        </div>
      </div>
    </div>

  </div>
</div>



.
