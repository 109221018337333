<div class="page">
  <h3>
    Пользователь
  </h3>
  <form *ngIf="user" (ngSubmit)="sendUser()">
    <mat-form-field>
      <mat-label>Имя пользователя</mat-label>
      <input matInput type="text" name="userName" [(ngModel)]="user.userName">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput type="text" name="Email" [(ngModel)]="user.email">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput type="password" name="PasswordHash" [(ngModel)]="user.PasswordHash">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Роль полльзователя</mat-label>
      <mat-select [(value)]="userRole">
        <mat-option value="user">
          Пользователь
        </mat-option><mat-option value="admin">
          Администратор
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-button type="submit" mat-stroked-button color="primary">
      Сохранить
    </button>
  </form>
</div>
