import { Component, OnInit } from '@angular/core';
import {FeedbackService} from '../shared/services/feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  feedbackList: any[];

  settings = {
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      ip: {
        title: 'IP'
      },
      login: {
        title: 'Login'
      },
      readDate: {
        title: 'Дата'
      },
      email: {
        title: 'Email'
      },
      title: {
        title: 'Заголовок'
      },
      text: {
        title: 'Текст'
      }
    }
  };

  constructor(private feedback: FeedbackService) { }

  ngOnInit(): void {
    this.getAllFeedbacks();
  }

  getAllFeedbacks() {
    this.feedback.getAllFeedback().subscribe(data => {
      console.log(data);
      this.feedbackList = data as any[];
    });
  }
}
