import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FinancialinstrumentsService} from "../../shared/services/financialinstruments.service";
import {NotifierMsgService} from "../../shared/services/notifier-msg.service";

@Component({
  selector: 'app-instrument-edit',
  templateUrl: './instrument-edit.component.html',
  styleUrls: ['./instrument-edit.component.css']
})
export class InstrumentEditComponent implements OnInit {
  id: any = undefined;
  instrument: FormGroup;

  newInstrument: FormGroup;
  constructor(private route: ActivatedRoute,
              private instrumentService: FinancialinstrumentsService,
              private notifier: NotifierMsgService,
              private router: Router) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.KeyId;
    this.instrument = new FormGroup({
      code: new FormControl('', [Validators.required])
    });

    this.newInstrument = new FormGroup({
      title: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required])
    });
  }

  sendInstrument() {
    this.instrumentService.postFinancialinstruments(this.instrument.value).subscribe( data => {
      console.log(data);
      this.notifier.notifier.notify('success', 'Инструмент добавлен');
      this.router.navigate(['/instruments-page']);
    }, error => {
      console.log(error);
      this.notifier.notifier.notify('warning', 'Что-то пошло не так!');
    });
  }

  createNewInstrument() {
    this.instrumentService.createFinancialInstrument(this.newInstrument).subscribe( data => {
      console.log(data);
      this.notifier.notifier.notify('success', 'Инструмент добавлен');
      this.router.navigate(['/instruments-page']);
    }, error => {
      console.log(error);
      this.notifier.notifier.notify('warning', 'Что-то пошло не так!');
    });
  }
}
