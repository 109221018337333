import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './user/login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {InstrumentEditComponent} from "./instrument-page/instrument-edit/instrument-edit.component";
import {MailingPageComponent} from './mailing-page/mailing-page.component';
import {LogPageComponent} from './log-page/log-page.component';
import {InstrumentPageComponent} from './instrument-page/instrument-page.component';
import {ErrorPageComponent} from './error-page/error-page.component';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {DefaultFinInstComponent} from './default-fin-inst/default-fin-inst.component';
import {UsersComponent} from "./user/users/users.component";
import {EditUserComponent} from "./user/edit-user/edit-user.component";
import {FeedbackComponent} from "./feedback/feedback.component";
import {RecoverPasswordComponent} from "./recover-password/recover-password.component";
import { UploadImgComponent } from './upload-img/upload-img.component';


const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'login', component: LoginComponent },
  { path: 'default', component: DefaultFinInstComponent },
  { path: 'mailing-page', component: MailingPageComponent},
  { path: 'log-page', component: LogPageComponent},
  { path: 'instruments-page', component: InstrumentPageComponent},
  { path: 'instrument-edit/:KeyId', component: InstrumentEditComponent},
  { path: 'instrument-create', component: InstrumentEditComponent},
  { path: 'users', component: UsersComponent},
  { path: 'users/:KeyId', component: EditUserComponent},
  { path: 'feedback', component: FeedbackComponent },
  { path: 'upload-img', component: UploadImgComponent },

  {path: 'requests-recover-password', component: RecoverPasswordComponent},

  { path: '404', component: ErrorPageComponent},
  { path: '**', component: ErrorPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [
    RouterModule,
    MatIconModule,
    MatListModule
  ]
})
export class AppRoutingModule { }
