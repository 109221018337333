import { Component, OnInit } from '@angular/core';
import {User} from "../../shared/interfaces/User";
import {UsersServices} from "../../shared/services/users.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  user: User;
  userId: number;
  userRole = 'user';
  constructor(private usersService: UsersServices,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.userId = this.route.snapshot.params.KeyId;
    this.getUser();
  }

  getUser() {
    this.usersService.getUser(this.userId).subscribe( user => {
      this.user = user as User;
      console.log(this.user);
      this.userRole = this.user.role
      console.log(this.user);
    });
  }

  sendUser() {
    this.user.role = this.userRole;

    this.usersService.senUser(this.user).subscribe( user => {
      console.log(user);
      this.router.navigate(['/users']);
    })
  }
}
