import { Component, OnInit } from '@angular/core';
import { FileUploadService } from '../shared/services/file-upload.service';
import { NotifierMsgService } from "../shared/services/notifier-msg.service";
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-upload-img',
  templateUrl: './upload-img.component.html',
  styleUrls: ['./upload-img.component.css']
})
export class UploadImgComponent implements OnInit {

  constructor(private fileUploadService: FileUploadService,
              private notifier: NotifierMsgService)
  { }


  ngOnInit(): void {
  }

  uploadFile(fileToUpload) {
    const formData = new FormData();
    formData.append('uploadedFiles', fileToUpload);
    const sizeFile = Math.round(fileToUpload.size / 1024 / 1024);
    if (sizeFile > 100) {
      this.notifier.notify('error', 'Размер файла не должен превышать 100mb');
    } else {

      this.fileUploadService.uploadFile(true, formData).subscribe((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) { }
        if (event.type === HttpEventType.Response) {
          const f = ((event.body as any) as any[])[0];
          this.notifier.notify("info", "Файл успено загружен");
          //this.fileUpload = event.body[0];
          //this.doctor.photoId = this.fileUpload.id;
          //this.app.overlayUpload = false;
        }
      }, (e) => {
        setTimeout(() => location.reload(), 1500);
      });
    }


  }

}
