import { Injectable } from '@angular/core';
import {NotifierService} from "angular-notifier";

@Injectable({
  providedIn: 'root'
})
export class NotifierMsgService {

  constructor(public notifier: NotifierService) {
  }
  notify(str, msg){
    this.notifier.notify(str, msg);
  }
}
