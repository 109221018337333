<div class="page">
  <a routerLink="/">Назад</a>
  <h2>Загружать можно только <b>PNG</b>! Изображения название инструмента должно соответствовать его коду например московской бирже должно соответствовать название <b>MOEX.png</b></h2>

  <div class="form-group">
    <label for="file">Выберите иконку для инструмента на локальном диске:</label>
    <br />
    <input type="file"
           id="file"
           (change)="uploadFile($event.target.files[0])">
  </div>
</div>
<notifier-container></notifier-container>
