import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './user/login/login.component';
import {IndexComponent} from './user/index/index.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MailingPageComponent} from './mailing-page/mailing-page.component';
import {PreviewListComponent} from './mailing-page/preview-list/preview-list.component';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {NotifierModule} from 'angular-notifier';
import { LogPageComponent } from './log-page/log-page.component';
import {TokenInterceptor} from './shared/interceptors/token';
import { InstrumentPageComponent } from './instrument-page/instrument-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { InstrumentEditComponent } from './instrument-page/instrument-edit/instrument-edit.component';
import { DefaultFinInstComponent } from './default-fin-inst/default-fin-inst.component';
import { UsersComponent } from './user/users/users.component';
import { EditUserComponent } from './user/edit-user/edit-user.component';
import {MatTableModule} from "@angular/material/table";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import { FeedbackComponent } from './feedback/feedback.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { UploadImgComponent } from './upload-img/upload-img.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    IndexComponent,
    DashboardComponent,
    HeaderComponent,
    MailingPageComponent,
    PreviewListComponent,
    LogPageComponent,
    InstrumentPageComponent,
    ErrorPageComponent,
    InstrumentEditComponent,
    DefaultFinInstComponent,
    UsersComponent,
    EditUserComponent,
    FeedbackComponent,
    RecoverPasswordComponent,
    UploadImgComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    Ng2SmartTableModule,
    NotifierModule,
    MatTableModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatExpansionModule,
    MatProgressSpinnerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: TokenInterceptor
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
