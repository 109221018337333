import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from "@angular/common/http";
import {Conf} from "../conf";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

  uploadFile(payload, formData) {
    const idToken = localStorage.getItem('id_token');
    const headers = new HttpHeaders({
      'Authorization': 'bearer ' + idToken
    });
    const url_upload = Conf.APIBASEURL + 'api/appfiles';
    const uploadReq = new HttpRequest('POST', url_upload, formData, {
      headers: headers,
      reportProgress: true,
    });
    return this.http.request(uploadReq);
  }
}
