<app-header *ngIf="isLogin"></app-header>

<div class="container">
  <router-outlet></router-outlet>
</div>

<footer>
</footer>

<notifier-container></notifier-container>
