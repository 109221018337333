import { Component, OnInit } from '@angular/core';
import {DashboardService} from "../shared/services/dashboard.service";
import {DashboardInformation} from "../shared/interfaces/dashboard-information";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  information: DashboardInformation;
  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getDashboard();
  }

  getDashboard() {
    this.dashboardService.getDashboard().subscribe( data => {
      this.information = data as DashboardInformation;
      console.log('dashboard', this.information);
    });
  }
}
