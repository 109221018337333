<header>
  <a routerLink="/"
     class="logo">
    <img src="./assets/img/logo.png" alt="«НФК Сбережения» - брокерское обслуживание / НФК-Сбережения">
  </a>

  <div class="menu">

    <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon">
      <mat-icon (click)="menuOpen = !menuOpen">menu</mat-icon>
    </button>


    <div id="overlay-header"
         *ngIf="menuOpen"
         (click)="menuOpen = false">
    </div>

    <ul id="menu-header"
        [class.active]="menuOpen">
      <button (click)="menuOpen = false"
              id="close-menu">
        <img src="assets/img/icon/icon-close-black.svg"
             alt="Закрать">
      </button>

      <li>
        <a routerLink="/"
           (click)="menuOpen = false">
          Главная страница
        </a>
      </li>
      <li>
        <a routerLink="/instruments-page"
           (click)="menuOpen = false">
          Инструменты
        </a>
      </li>
<!--      <li>-->
<!--        <a routerLink="/default"-->
<!--           (click)="menuOpen = false">-->
<!--          Инструменты по умолчанию-->
<!--        </a>-->
<!--      </li>-->
      <li>
        <a routerLink="/users">
          Пользователи
        </a>
      </li>
      <li>
        <a routerLink="/mailing-page"
           (click)="menuOpen = false">
          Рассылка
        </a>
      </li>
      <li>
        <a routerLink="/log-page"
           (click)="menuOpen = false">
          Логи
        </a>
      </li>
      <li>
        <a routerLink="/feedback"
           (click)="menuOpen = false">
          Запросы на обратную связь
        </a>
      </li>
      <li>
        <a routerLink="/requests-recover-password"
           (click)="menuOpen = false">
          Запросы востановления пароля
        </a>
      </li>
      <li>
        <button (click)="exit()">
          Выход
        </button>
      </li>
    </ul>
  </div>
</header>

