<form (ngSubmit)="submitForm()"
      [formGroup]="loginForm"
      class="login-form">
  <div class="top">
    <h3>
      Здравствуйте!
    </h3>
    <p>
      Административная часть мобильного приложения
    </p>
  </div>

  <div class="bottom">
    <h3>
      Авторизация для клиентов
    </h3>
    <input type="tel"
           placeholder="Логин"
           name="login"
           formControlName="login">
    <input type="password"
           name="password"
           placeholder="Пароль"
           formControlName="password">
    <button type="submit" [disabled]="!loginForm.valid">
      Войти
    </button>
  </div>
</form>
