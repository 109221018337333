import { Component, OnInit } from '@angular/core';
import {PasswordService} from '../shared/services/password.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {

  recoverList: any[];
  settings = {
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      email: {
        title: 'Email'
      },
      inits: {
        title: 'Ф.И.О.'
      },
      nummer: {
        title: 'Номер'
      }
    }
  };
  constructor(private passwordService: PasswordService) { }

  ngOnInit(): void {
    this.getRecoverPassword();
  }

  getRecoverPassword() {
    this.passwordService.getRecoverPassword().subscribe( data => {
      // // @ts-ignore
      // data.map( el => {
      //   console.log(new DatePipe('en').transform(el.dateAdd.toString(), 'dd-MM-yyyy HH:mm'));
      // });
      this.recoverList = data as any[];
    });
  }
}
