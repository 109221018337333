import { Component, OnInit } from '@angular/core';
import {UsersServices} from "../../shared/services/users.service";
import {User} from "../../shared/interfaces/User";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users: User[] = [];
  settings = {
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      nummer: {
        title: 'ID'
      },
      email: {
        title: 'Email'
      },
      userName: {
        title: 'Ф.И.О.',
      },
      role: {
        title: 'Роль'
      },
      edit: {
        title: 'Редактировать',
        type: 'html',
        valuePrepareFunction: (col, row) => {
          return `<a href="/users/${row.nummer}">
                    Редактировать
                  </a>`;
        }
      }
    }
  };
  constructor(private usersService: UsersServices) { }

  ngOnInit(): void {
    this.getAllUsers();
  }

  getAllUsers() {
    this.usersService.getAllUsers().subscribe( users => {
      this.users = users as User[];
      // console.log(this.users)
    });
  }
}
