<div class="page">
  <h3>
    {{id?'Редактирование иснтрумента':'Создание инструмента'}}
  </h3>

  <form class="form-instrument"
        [formGroup]="instrument"
        (ngSubmit)="sendInstrument()">
    <h4>Добавить новый инструмент по умолчанию</h4>
    <label for="code">
      Код
    </label>
    <input type="text"
           formControlName="code"
           id="code">
    <button type="submit" class="main-button">
      Сохранить
    </button>
  </form>


  <form class="form-instrument"
        [formGroup]="newInstrument"
        (ngSubmit)="createNewInstrument()">
    <h4>
      Добавить новый финансовый инструмент
    </h4>
    <label for="title">
      Название
    </label>
    <input type="text"
           formControlName="title"
           id="title">
    <label for="new-code">
      Код
    </label>
    <input type="text"
           formControlName="code"
           id="new-code">
    <button type="submit" class="main-button">
      Сохранить
    </button>
  </form>
</div>
