<div class="page">
  <a routerLink="/">Назад</a>
  <h3>Инструменты</h3>

  <!--  <div class="btn-block">-->
  <!--    <button routerLink="/instrument-create" class="main-button">-->
  <!--      Создать-->
  <!--    </button>-->
  <!--  </div>-->

  <div class="instrument-list">
    <br />
    <ng2-smart-table *ngIf="instruments"
                     [settings]="settings"
                     (createConfirm)="createInstrument($event)"
                     (deleteConfirm)="deleteConfirm($event)"
                     (editConfirm)="confirmEdit($event, 'editConfirm')"
                     [source]="instruments"></ng2-smart-table>

    <mat-spinner *ngIf="instruments === undefined"></mat-spinner>
  </div>

  <div class="modal-delete-confirm" *ngIf="removeInst">
    <div class="modal-overlay" (click)="removeInst = undefined">

    </div>
    <div class="modal-window">
      <div class="modal-window__top">
        <div class="modal-window__title">
          Подтверждение удаления
        </div>
        <button class="close" (click)="removeInst = undefined">
          X
        </button>
      </div>
      <div class="modal-window__body">
        <p>Код: {{removeInst.code}}</p>
        <p>Сокращёный ключ: {{removeInst.clear_ode}}</p>
        <p>Ключ: {{removeInst.key}}</p>
        <p>Стандртный: {{removeInst.isDefault? 'Да': 'Нет'}}</p>
        <p>Описание: {{removeInst.description}}</p>
        <div class="modal-window__btn-block">
          <button class="confirm" (click)="deleteIstrument(removeInst)">
            Подтвердить удаление
          </button>
          <button class="cancel" (click)="removeInst = undefined">
            Отмена
          </button>
        </div>

      </div>
    </div>
  </div>
</div>
