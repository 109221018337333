<div class="page">
  <h3>Создание рассылки</h3>

  <form (ngSubmit)="filterDateRange()" class="range-date-filter" *ngIf="source !== undefined">
    <h4>
      Фильтр по датам рождения
    </h4>
    <div class="input-wrap">
      <div class="mat-form-field">
        <span>Минимальная дата(гггг-мм-дд)</span>
        <input type="text"
               [(ngModel)]="dateFilter.start"
               name="startDate"
               [pattern]="regExpDate">
      </div>

      <div class="mat-form-field">
        <span>Максимальная дата(гггг-мм-дд)</span>
        <input type="text"
               [(ngModel)]="dateFilter.end"
               name="endDate"
               [pattern]="regExpDate">
      </div>
    </div>
    <button
            type="submit">
      Применить
    </button>
  </form>

  <div class="btn-mailing" *ngIf="source !== undefined">
    <button (click)="modal = !modal; type = 'push'">
      Рассылка Push
    </button>
<!--    <button (click)="modal = !modal; type = 'email'">-->
<!--      Рассылка Email-->
<!--    </button>-->
  </div>

  <ng2-smart-table [settings]="settingsTable"
                   [source]="source"
                   *ngIf="source"
                   (userRowSelect)="onUserRowSelect($event)">
  </ng2-smart-table>
</div>


<app-preview-list (open)="close($event)"
                  [type]="type"
                  [modal]="modal"
                  [data]="source" *ngIf="modal">
</app-preview-list>
<notifier-container></notifier-container>
