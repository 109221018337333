import {Component, OnInit, ViewChild} from '@angular/core';
// import {AvatarTableComponent} from "../shared/component/smart-table/avatar-table/avatar-table.component";
// import {EditBtnTableComponent} from "../shared/component/smart-table/edit-btn-table/edit-btn-table.component";
// import {Patient} from "../shared/interfaces/patient";
import {DatePipe} from '@angular/common';
import {LocalDataSource} from 'ng2-smart-table';
import { UsersServices} from '../shared/services/users.service';
import {NotifierMsgService} from '../shared/services/notifier-msg.service';
import {PreviewListComponent} from './preview-list/preview-list.component';

@Component({
  selector: 'app-mailing-page',
  templateUrl: './mailing-page.component.html',
  styleUrls: ['./mailing-page.component.scss']
})
export class MailingPageComponent implements OnInit {
  patients: any[];
  source: LocalDataSource;
  @ViewChild(PreviewListComponent) comp: PreviewListComponent;

  type = 'push';
  modal = false;

  dateFilter = {
    start: '',
    end: ''
  };
  regExpDate = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/g;

  constructor(private usersService: UsersServices,
              private notifier: NotifierMsgService) { }

  settingsTable = {
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      email: {
        title: 'Email',
      },
      phone: {
        title: 'Телефон',
      },
      dateBirth: {
        title: 'Дата Рождения',
        filter: false,
        filterFunction(cell?: any, search?: string): boolean {
          if (cell >= search || search === '') {
            return true;
          } else {
            return false;
          }
        }
      },
      sex: {
        title: 'Пол',
        valuePrepareFunction: (cell, row) => {
          if (row.sex) {
            return 'Мужской'
          } else {
            return 'Женский'
          }
        }
      },
      transformRole: {
        title: 'Статус'
      }
    }
  };
  close(e){
    this.modal = e;
  }
  ngOnInit(): void {
    console.log(this.patients);
    this.getUsers();
  }
  getUsers() {
    this.usersService.getAllUsers().subscribe(users => {
      this.patients = users as any[];
      console.log('users', users);
      for (let n = 0; n < this.patients.length; n++) {

        // поля для фильтров в таблице
        // @ts-ignore
        this.patients[n].transformName = (this.patients[n].firstName ? this.patients[n].firstName : '') + ' ' +
          // @ts-ignore
          (this.patients[n].secondName ? this.patients[n].secondName : '') + ' ' +
          // @ts-ignore
          (this.patients[n].patronymic ? this.patients[n].patronymic : '');
        this.patients[n].checked = false;
        // @ts-ignore
        this.patients[n].phone = this.patients[n].phoneNumber;
        // @ts-ignore
        this.patients[n].dateBirth = new DatePipe('en').transform(this.patients[n].dateBirth, 'yyyy-MM-dd');
        // @ts-ignore
        switch (this.patients[n].role) {
          case 'admin':
            // @ts-ignore
            this.patients[n].transformRole = 'Администратор'
            break;
          case 'doc':
            // @ts-ignore
            this.patients[n].transformRole = 'Врач'
            break;
          case 'user':
            // @ts-ignore
            this.patients[n].transformRole = 'Пациент'
            break;
        }
      }
      this.source = new LocalDataSource(this.patients)
    });
  }
  public onUserRowSelect(event) {
    var selectedRows = event.selected;
  }
  filterDateRange() {
    // @ts-ignore
    if(this.source.filterConf.filters.length === 0){
      const filterVal = {
        start: new Date(this.dateFilter.start),
        end: new Date(this.dateFilter.end)
      }
      const result = this.patients.filter(function (a) {
        // @ts-ignore
        const dateUser = new Date(a.dateBirth)
        return (dateUser >= filterVal.start && dateUser <= filterVal.end);
      });
      this.source = new LocalDataSource(result);
    } else {
      // filteredAndSorted
      const filterVal = {
        start: new Date(this.dateFilter.start),
        end: new Date(this.dateFilter.end)
      }
      // @ts-ignore
      const result = this.source.filteredAndSorted.filter(function (a) {
        // @ts-ignore
        const dateUser = new Date(a.dateBirth)
        return (dateUser >= filterVal.start && dateUser <= filterVal.end);
      });
      this.source = new LocalDataSource(result);
    }
    console.log(this.source);
  }
}
