import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Conf} from "../conf";

@Injectable({
  providedIn: 'root'
})
export class FinancialinstrumentsService {

  constructor(private http: HttpClient) { }

  //Получить фильтры финансовых инструментов
  getFinancialinstruments(){
    return this.http.get(`${Conf.APIBASEURL}api/financialinstruments/all`);
  }

  // Добавить новый финансовый инструмент(Админ)
  postFinancialinstruments(instrument){
    return this.http.post(`${Conf.APIBASEURL}/api/DefaultFinancialInstruments`, instrument);
  }

  putFinancialinstruments(instrument){
    return this.http.put(`${Conf.APIBASEURL}api/financialinstruments`, instrument);
  }

  // удалить финансовый инструмент
  deleteFinancialinstruments(id) {
    return this.http.delete(`${Conf.APIBASEURL}api/financialinstruments`, id);
  }

  createFinancialInstrument(instrument) {
    return this.http.post(`${Conf.APIBASEURL}api/financialinstruments`, instrument);
  }
}
