import { Component, OnInit } from '@angular/core';
import {LogsService} from '../shared/services/logs.service';
import {Log} from '../shared/interfaces/logs';

@Component({
  selector: 'app-log-page',
  templateUrl: './log-page.component.html',
  styleUrls: ['./log-page.component.css']
})
export class LogPageComponent implements OnInit {

  logs: Log[];
  constructor(private logsServices: LogsService) { }

  ngOnInit(): void {
    this.getLogs();
  }

  getLogs() {
    this.logsServices.getLogs().subscribe( data => {
      this.logs = data as Log[];
      console.log('logs', this.logs);
    });
  }
}
