import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "./shared/services/auth.service";
import {log} from "util";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'web-app';
  isLogin: boolean;

  activeRouteApp: any;
  constructor(private router: Router,
              private auth: AuthService,
              private route: ActivatedRoute) {
    this.router.events.subscribe((evt) => {
      this.isLogin = this.auth.isAuth();
      this.activeRouteApp = this.router.routerState.snapshot.url;
    });
  }
  ngOnInit() {
    this.isLogin = this.auth.isAuth();
    if (!this.isLogin) {
      this.router.navigate(['/login']);
    }
  }
}
