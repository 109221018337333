import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Conf} from '../conf';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(private http: HttpClient) { }

  // Вернуть все логи (Админ)
  getLogs(){
    return this.http.get(`${Conf.APIBASEURL}api/logs`);
  }
}
