<div class="overlay-list" (click)="closeModal(modal)">
</div>

<div class="mailing-wrap">
  <div class="head">
    <h3 *ngIf="type === 'push'">
      Push-рассылка
    </h3>
    <h3 *ngIf="type === 'email'">
      Email-рассылка
    </h3>
  </div>
  <form (ngSubmit)="confirmMailing()" class="mailing-form" *ngIf="!confirm">

    <div class="mat-form-field">
      <span>Загаловок:</span>
      <input type="text"
             matInput
             [(ngModel)]="msg.title"
             name="msgTitle">
    </div>

    <div class="mat-form-field">
      <span>Текст:</span>
      <textarea  cols="30"
                 rows="3"
                 [(ngModel)]="msg.text" name="msgText">
      </textarea>
    </div>

    <div class="btn-wrap">
      <button
              type="submit"
              [disabled]="msg.title.length === 0 || msg.text.length === 0">
        Отправить
      </button>
      <button (click)="closeModal(modal)">
        Отменить
      </button>
    </div>
  </form>

  <div class="msg-wrap" *ngIf="confirm">
    <h3>
      Загаловок:
    </h3>
    <p>
      {{msg.title}}
    </p>
    <h3>
      Текст:
    </h3>
    <p>
      {{msg.text}}
    </p>
    <div class="btn-wrap">
      <button (click)="sendMailing()"
              [disabled]="msg.title.length === 0 || msg.text.length === 0">
        Подтвенрдить
      </button>
      <button (click)="confirm = !confirm">
        Назад
      </button>
      <button (click)="closeModal(modal)">
        Отменить
      </button>
    </div>
  </div>

  <h3>
    Список для рассылки
  </h3>
  <div class="table-wrap">

    <table>
      <thead>
        <tr>
          <td>
            Ф.И.О.
          </td>
          <td *ngIf="type === 'push'">
            Телефон
          </td>
          <td *ngIf="type === 'email'">
            Email
          </td>
          <td *ngIf="type === 'push'">
            Роль
          </td>
          <td *ngIf="!confirm">
            Удалить
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of this.data.filteredAndSorted; let i = index">
          <td>
            {{user.transformName}}
          </td>
          <td *ngIf="type === 'push'">
            {{user.phoneNumber}}
          </td>
          <td *ngIf="type === 'email'">
            {{user.email}}
          </td>
          <td *ngIf="type === 'push'">
            {{user.transformRole}}
          </td>
          <td>
            <button *ngIf="!confirm"
                    (click)="deleteItem(i)">
              Исключить
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
