import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Conf} from "../conf";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getDashboard() {
    return this.http.get(`${Conf.APIBASEURL}api/dashboard`);
  }
}
