import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Conf} from '../../shared/conf';
import {NotifierMsgService} from '../../shared/services/notifier-msg.service';

@Component({
  selector: 'app-preview-list',
  templateUrl: './preview-list.component.html',
  styleUrls: ['./preview-list.component.scss']
})
export class PreviewListComponent implements OnInit {

  msg = {
    title: 'Загаловок',
    text: 'Текст'
  };
  @Input() modal: boolean;
  @Input() type: string;
  @Input() data: any;
  @Output() open = new EventEmitter<boolean>();
  confirm = false;
  arrMailing: any[] = [];

  constructor(private http: HttpClient,
              public notifier: NotifierMsgService) { }

  closeModal(modal) {
    this.modal = !modal;
    this.open.emit(this.modal);
  }
  ngOnInit(): void {
  }
  confirmMailing() {
    this.confirm = true;
  }
  sendMailing() {
    this.generateMailingUsers();
    if (this.arrMailing.length === 0) {
      this.notifier.notify('error', 'Список пуст');
    } else {
      switch (this.type) {
        case 'push':
          this.pushMailing();
          break;
        case 'email':
          this.emailMailing();
          break;
      }
    }
  }

  pushMailing(){
    this.http.post(`${Conf.APIBASEURL}api/push`, this.arrMailing).subscribe( data => {
        this.modal = false;
        this.open.emit(this.modal);
        this.notifier.notify('success', 'Рассылка создана');
      },
      error => {
        console.log(error);
      }
    );
  }
  emailMailing(){
    this.http.post(`${Conf.APIBASEURL}api/mail`, this.arrMailing).subscribe( data => {
        this.modal = false;
        this.open.emit(this.modal);
        this.notifier.notify('success', 'Рассылка создана');
      },
      error => {
        console.log(error);
      }
    );
  }
  //генирация массива для рассылки
  generateMailingUsers() {
    for ( let i = 0; i < this.data.filteredAndSorted.length; i++) {
      this.arrMailing[i] = {
        phone: this.type === 'push' ? this.data.filteredAndSorted[i].phoneNumber : this.data.filteredAndSorted[i].userName,
        isNotification: true,
        title: this.msg.title,
        text: this.msg.text,
        type: 30
      };
    }
  }

  deleteItem(i: number) {
    this.data.filteredAndSorted.splice(i, 1);
  }
}
