import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Conf} from '../conf';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(private http: HttpClient) {
  }

  getRecoverPassword() {
    return this.http.get(`${Conf.APIBASEURL}api/RequestsRecoverPassword`);
  }
}
