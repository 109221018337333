import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../shared/services/auth.service';
import {Router} from '@angular/router';
import {NotifierMsgService} from "../../shared/services/notifier-msg.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public auth: AuthService,
              private router: Router, private notifier: NotifierMsgService) { }

  loginForm: FormGroup

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      login: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  submitForm() {
    console.log('loginForm', this.loginForm.value);

    this.auth.sendLogin(this.loginForm.value).subscribe( data => {
      // @ts-ignore
      localStorage.setItem('id_token', data.text);
      this.router.navigate(['/dashboard']);
    }, error => {
      this.notifier.notify('error', 'Неверный логин либо пароль!');
      console.log(error);
    });
  }
}
