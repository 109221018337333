import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Conf} from '../conf';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  sendLogin(user) {
    return this.http.post(`${Conf.APIBASEURL}auth/loginadmin`, user);
  }
  isAuth(){
    if (localStorage.id_token){
      return true;
    } else {
      return false;
    }
  }
}
